import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
        minHeight: 350,
        position: 'relative'
    },
    cardmedia: {
        height: 140
    },
    cardcontent: {
        height: 125
    },
    cardaction: {
        paddingLeft: 16
    },
});

const SkeletonPreview = () => {
  const classes = useStyles();

    return(
        <Card className={classes.root}>
            <Skeleton animation="wave" variant="rect" className={classes.cardmedia} />
            <CardContent className={classes.cardcontent}>
                <React.Fragment>
                    <Skeleton animation="wave" height={15} style={{marginBottom: 4}} />
                    <Skeleton animation="wave" height={45} width="32%" style={{marginBottom: 15}} />
                    <Skeleton animation="wave" height={12} width="70%" style={{marginBottom: 10}} />
                    <Skeleton animation="wave" height={12} width="70%" style={{marginBottom: 10}} />
                    <Skeleton animation="wave" height={12} width="70%" />
                </React.Fragment>
            </CardContent>
            <CardActions className={classes.cardaction}>
                <table width="100%" style={{borderSpacing: 0}}>
                    <tbody>
                        <tr>
                            <td width="21%">
                                <Skeleton animation="wave" height={51} width="100%" />
                            </td>
                            <td width="0.5%">
                                &nbsp;
                            </td>
                            <td width="35.5%">
                                <Skeleton animation="wave" height={51} width="100%" />
                            </td>
                            <td width="43%">
                                &nbsp;
                            </td>
                        </tr>
                    </tbody>
                </table>
            </CardActions>
        </Card>
    )
}

export default SkeletonPreview