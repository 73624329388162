import React, { Fragment } from "react";
import { Link } from 'react-router-dom';
import { Container } from "@material-ui/core";
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const useStyles = makeStyles((theme) =>
  createStyles({
    link: {
        textAlign:"center",
        '& a': {
            textDecoration: 'none',
            fontWeight: 'bold',
            color: '#f50057'
        }
    },
  }),
);

const NotFound = () =>{
    const classes = useStyles();

    return(
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <title>Not Found - MYFDRATES.COM</title>
                    <meta name="description" content="Oops, looks like the page is lost!" />
                </Helmet>
            </HelmetProvider>
            <Container>
                <div>
                    <h1>404 Not Found!</h1>
                    <p className={classes.link}>
                        <Link to="/">Go to Home</Link>
                    </p>
                </div>
            </Container>
        </Fragment>
    )
}

export default NotFound;