import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import firebase from '../firebase';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FiberNewIcon from '@material-ui/icons/FiberNew';

import cimb from '../static/images/cards/cimb.jpg';
import maybank from '../static/images/cards/maybank.png';
import affin from '../static/images/cards/affin.png';
import ambank from '../static/images/cards/ambank.jpg';
import br from '../static/images/cards/br.jpg';
import hlb from '../static/images/cards/hlb.jpg';
import rhb from '../static/images/cards/rhb.jpg';
import uob from '../static/images/cards/uob.jpg';
import ocbc from '../static/images/cards/ocbc.png';
import icbc from '../static/images/cards/icbc.jpg';
import pb from '../static/images/cards/pb.jpg';
import mbsb from '../static/images/cards/mbsb.jpg';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    minHeight: 350,
    position: 'relative'
  },
  title: {
    fontSize: 17,
    fontWeight: 'bold',
    height: 68,
    '& div': {
      marginTop: 5
    }
  },
  spantitle: {
    fontSize: 12,
    fontWeight: 500,
    border: '1px solid #646464',
    padding: '4px 8px',
    borderRadius: 3,
    lineHeight: 3
  },
  cardcontent: {
    height: 125
  },
  cardmedia: {
    borderBottom: '1px solid #ededed'
  },
  cardaction: {
    padding: 16
  },
  actionArea: {
    "&:hover $focusHighlight": {
      opacity: 0
    }
  },
  focusHighlight: {},
  overlaydiv: {
    position: 'absolute',
    display: 'block',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#888885',
    opacity: 0.5,
    zIndex: 1,
  },
  overlaytext: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    fontSize: 20,
    fontWeight: 'bolder',
    color: 'white',
    transform: 'translate(-50%,-50%)',
    backgroundColor: '#000000',
    padding: '10px 20px',
  },
  spanicon: {
    paddingRight: 40
  },
  icon: {
    position: 'absolute'
  },
});

const CardItem = ({details}) => {
  const classes = useStyles();
  const [likes, setLikes] = useState([]);
  const [disabled, setDisabled] = useState([]);

  useEffect(() => {
    let isAbort = false;
    let ref = firebase.database().ref('/');
    ref.on('value', snapshot => {
      const state = snapshot.val();
      if(!isAbort){
        setLikes(state);
      }
    });
    return () => {
      isAbort = true;
    };
  }, []);

  const getPostLikes = (id) => {
    const articleid = 'article' + id;
    let value = likes[articleid];
    if (!value) {
      value = 0;
    }
    return value;
  }

  const updatePostLikes = (e, id) => {
    e.preventDefault();
    setDisabled([...disabled, id]);
    const articleid = 'article' + id;
    likes[articleid] = likes[articleid] + 1;
    
    if(!likes[articleid]){
      likes[articleid] = 1;
    }

    updateToFirebase();
  }

  const updateToFirebase = () => {
    firebase.database().ref('/').set(likes);
  }

  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const convertDateToString = (dateString) => {
    let dateParts = dateString.split("/");
    let dateObject = new Date(+dateParts[2], `${dateParts[1] - 1}`.padStart(2, "0"), `${dateParts[0]}`.padStart(2, "0"));
    return dateObject.getDate() + " " + monthNames[dateObject.getMonth()] + " " + dateObject.getFullYear();
  }

  const isDateExpired = (dateString) => {
    let dateParts = dateString.split("/");
    let dateObject = new Date(+dateParts[2], `${dateParts[1] - 1}`.padStart(2, "0"), `${dateParts[0]}`.padStart(2, "0"));
    return new Date(dateObject.toDateString()) < new Date(new Date().toDateString());
  }

  const isDateNew = (expiryDateString, dateString) => {
    let dateParts = dateString.split("/");
    let dateObject = new Date(+dateParts[2], `${dateParts[1] - 1}`.padStart(2, "0"), `${dateParts[0]}`.padStart(2, "0"));
    const thirtyDays = (60 * 60 * 24 * 1000) * 30;
    if(isDateExpired(expiryDateString)){
      return false;
    }
    return new Date(new Date().toDateString()) - new Date(dateObject.toDateString()) <= thirtyDays;
  }

  return (
    <Card className={classes.root}>
      {
        isDateExpired(details.expired)
        ? <div className={classes.overlaydiv}>
            <div className={classes.overlaytext}>
              EXPIRED
            </div>
          </div> 
        : null
      }
      <CardActionArea
        classes={{
          root: classes.actionArea,
          focusHighlight: classes.focusHighlight
        }}
      >
        <CardMedia
          component="img"
          alt={details.bank.toUpperCase() + ' - ' + details.name}
          height="140"
          image={
            details.code === 'cimb' ? cimb : details.code === 'maybank' ? maybank : 
            details.code === 'affin' ? affin : details.code === 'ambank' ? ambank :
            details.code === 'br' ? br :  details.code === 'hlb' ? hlb :
            details.code === 'rhb' ? rhb : details.code === 'uob' ? uob :
            details.code === 'ocbc' ? ocbc : details.code === 'icbc' ? icbc : 
            details.code === 'pb' ? pb : mbsb
          }
          title={details.bank.toUpperCase() + ' - ' + details.name}
          className={classes.cardmedia}
        />
        <CardContent className={classes.cardcontent}>
          <Typography gutterBottom variant="h5" component="h2" className={classes.title}>
            {details.name}
            <div>
              { details.date 
                ? isDateNew(details.expired, details.date)
                  ? <span className={classes.spanicon}>
                      <FiberNewIcon className={classes.icon} color="secondary" fontSize="large" />
                    </span>
                  : null
                : null
              }
              <span className={classes.spantitle}>
                {details.bank}
              </span>
            </div>
          </Typography>
          <Typography variant="body2" component="p">
            OTC Rate: {details.otc ? details.otc : '-'} <br/>
            EFD Rate: {details.efd ? details.efd : '-' } <br/>
            Expired: {
              convertDateToString(details.expired)
            }
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.cardaction}>
        <Button 
          size="small" 
          variant="outlined" 
          color="secondary" 
          startIcon={<FavoriteIcon />}
          disabled={disabled.indexOf(details.id)!==-1}
          onClick={(e) => updatePostLikes(e, details.id)}
        >
          {getPostLikes(details.id)}
        </Button>
        <Button 
          size="small"
          variant="contained"
          color="primary"
          target="_blank"
          disabled={!details.link}
          href={details.link}
        >
          Learn More
        </Button>
      </CardActions>
    </Card>
  );
}

export default CardItem;