import firebase from 'firebase/app';
import 'firebase/database';

const config = {
  apiKey: "AIzaSyAa87txDUsbd69Ja1d8dafkgqLkqQ8_r_Y",
  authDomain: "myfdrates.firebaseapp.com",
  databaseURL: "https://myfdrates.firebaseio.com",
  projectId: "myfdrates",
  storageBucket: "myfdrates.appspot.com",
}
  
firebase.initializeApp(config);
export default firebase;