import React, { useContext, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import { createStyles, fade, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import Button from '@material-ui/core/Button';
import { FilterContext } from './FilterContext';
import { Link } from 'react-router-dom';
import InfoIcon from '@material-ui/icons/Info';
import HelpIcon from '@material-ui/icons/Help';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import HistoryIcon from '@material-ui/icons/History';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      fontSize: '2.5rem'
    },
    title: {
      // flexGrow: 2,
      display: 'block',
      fontWeight: 'bold',
      // [theme.breakpoints.up('sm')]: {
      //   display: 'block',
      // },
      '& a': {
        textDecoration: 'none',
        color: '#FFFFFF'
      }
    },
    search: {
      position: 'relative',
      display: 'none',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
        display: 'inline-flex'
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    clearIcon: {
      display: 'inline-block',
      top: '7px',
      position: 'relative',
      paddingRight: '5px',
      cursor: 'pointer'
    },
    clearIconRoot: {
      fontSize: '22px',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1.2, 1.2, 1.2, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '15ch',
        '&:focus': {
          width: '18ch',
        },
      },
    },
    headerButton: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'inline-flex',
        padding: '8px 10px',
      },
    },
    // contactUs: {
    //   display: 'none',
    //   [theme.breakpoints.up('md')]: {
    //     display: 'inline-flex',
    //     padding: '8px 10px',
    //   },
    // },
    divSeparator: {
      flexGrow: 8
    },
    divSeparatorFixed: {
      width: '5rem'
    },
    headerIcon: {
      '& > *:first-child': {
        fontSize: 20
      },
    },
  }),
);

const Header = () => {
  const classes = useStyles();
  const [keyword, setKeyword] = useState('');
  const [, setFilter] = useContext(FilterContext);

  const updateKeyword = (e) => {
    setKeyword(e.target.value);
  }

  const filterByName = e => {
    e.preventDefault();
    setFilter(keyword);
  }

  const handleKeyDown = e => {
    if(e.key === 'Enter'){
        e.preventDefault();
        filterByName(e);
     }
  }

  const filterByLatestPast = (keyword) => (e) => {
    e.preventDefault();
    setFilter(keyword);
  }

  const resetFilter = e => {
    e.preventDefault();
    setKeyword('');
    setFilter('');
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          {/* <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
          >
            <MenuIcon />
          </IconButton> */}
          <MonetizationOnIcon
            edge="start"
            className={classes.menuButton}
            color="inherit"
          />
          <Typography onClick={resetFilter} className={classes.title} variant="h6" noWrap>
            <Link to="/">MYFDRATES.COM</Link>
          </Typography>
          <div className={classes.divSeparatorFixed}>&nbsp;</div>
          <div onClick={filterByLatestPast('latest')}>
            <Button
              component={Link}
              className={classes.headerButton}
              classes={{
                iconSizeSmall: classes.headerIcon
              }}
              style={{marginRight: 30}}
              startIcon={<NewReleasesIcon />}
              to="/"
              variant="contained"
              color="secondary"
              size="small"
            >
              Latest
            </Button>
          </div>
          <div onClick={filterByLatestPast('past')}>
            <Button
              component={Link}
              className={classes.headerButton}
              classes={{
                iconSizeSmall: classes.headerIcon
              }}
              style={{marginRight: 30}}
              startIcon={<HistoryIcon />}
              to="/"
              variant="contained"
              color="secondary"
              size="small"
            >
              Past
            </Button>
          </div>
          <Button
            component={Link}
            className={classes.headerButton}
            classes={{
              iconSizeSmall: classes.headerIcon
            }}
            style={{marginRight: 30}}
            startIcon={<InfoIcon/>}
            to="/about"
            variant="contained"
            color="secondary"
            size="small"
          >
            About Us
          </Button>
          <Button
            component={Link}
            className={classes.headerButton}
            classes={{
              iconSizeSmall: classes.headerIcon
            }}
            startIcon={<HelpIcon />}
            to="/contact"
            variant="contained"
            color="secondary"
            size="small"
          >
            Contact Us
          </Button>
          <div className={classes.divSeparator}>&nbsp;</div>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search bank..."
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              value={keyword}
              onChange={updateKeyword}
              onKeyDown={handleKeyDown}
            />
            {
              keyword ? <div className={classes.clearIcon}>
                          <ClearIcon
                            classes={{
                              root: classes.clearIconRoot,
                            }}
                            onClick={resetFilter}
                          />
                        </div> 
                      : null
            }
            <Button 
              size="small"
              variant="contained"
              color="secondary"
              onClick={filterByName}
            >
                Search
            </Button>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Header;