import React, { useState, useEffect, useContext, Fragment } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CardItem from '../../components/CardItem';
import data from '../../static/data/20200707/data';
import { FilterContext } from '../../components/FilterContext';
// import LazyLoad from 'react-lazyload';
// import Spinner from '../../components/Spinner';
import Skeleton from '../../components/Skeleton';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import InfiniteScroll from "react-infinite-scroll-component";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
        padding: theme.spacing(3)
    },
    paper: {
      padding: theme.spacing(2)
    },
    noData: {
        width: '100%',
        padding: '35px 0px 50px 40px'
    },
    skeletonLgMd: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    skeletonSm: {
        [theme.breakpoints.between('md', 'xl')]: {
            display: 'none',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    skeletonXs: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
        },
    },
  }),
);

const Dashboard = () =>{
    const classes = useStyles();
    const [promoList, setPromoList] = useState([]);
    const [filter, ] = useContext(FilterContext);
    const [hasMore, setHasMore] = useState(true);
    const [dataMessage, setDataMessage] = useState('');
    const dataSkeleton1 = Array.from({length: 1}, (v, i) => i);
    const dataSkeleton2 = Array.from({length: 2}, (v, i) => i);
    const dataSkeleton3 = Array.from({length: 3}, (v, i) => i);

    useEffect(() => {
        const filterData = (value) => {
            let filtered = data.sort((a, b) =>  convertDate(b.expired) - convertDate(a.expired));
            if(value !== ''){
                if(value !== 'latest' && value !== 'past'){
                    filtered = data.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.includes(value.toLowerCase())));
                } else {
                    if(value === 'latest'){
                        filtered = data.filter((d) => {
                            if(!d.date || isDateExpired(d.expired)){
                                return false;
                            }
                            let dateParts = d.date.split("/");
                            let dateObject = new Date(+dateParts[2], `${dateParts[1] - 1}`.padStart(2, "0"), `${dateParts[0]}`.padStart(2, "0"));
                            const thirtyDays = (60 * 60 * 24 * 1000) * 30;
                            return new Date(new Date().toDateString()) - new Date(dateObject.toDateString()) <= thirtyDays;
                        }).sort((a, b) =>  b.id - a.id);
                    } else {
                        filtered = data.filter((d) => {
                            return isDateExpired(d.expired);
                        }).sort((a, b) =>  convertDate(b.expired) - convertDate(a.expired));
                    }
                }
                setHasMore(false);
            } else {
                filtered = data.slice(0, 9);
                setHasMore(true);
            }
            if(filtered.length <= 0){
                setDataMessage('Data not found!');
                setHasMore(false);
            } else {
                setDataMessage('');
            }
            setPromoList(filtered);
        };
        
        filterData(filter);
    }, [filter]);

    const fetchMoreData = () => {
         if (promoList.length >= data.length) {
            setHasMore(false);
            return;
        }

        setTimeout(() => {
            const count = promoList.length + 9;
            const latestData = data.slice(0, count);
            setPromoList(latestData);
        }, 1000);
    };

    const convertDate = (dateString) => {
        let dateParts = dateString.split("/");
        return new Date(+dateParts[2], `${dateParts[1] - 1}`.padStart(2, "0"), `${dateParts[0]}`.padStart(2, "0"));
    }

    const isDateExpired = (dateString) => {
        let dateParts = dateString.split("/");
        let dateObject = new Date(+dateParts[2], `${dateParts[1] - 1}`.padStart(2, "0"), `${dateParts[0]}`.padStart(2, "0"));
        return new Date(dateObject.toDateString()) < new Date(new Date().toDateString());
    }

    return(
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <title>MYFDRATES.COM - Latest FD Rates Malaysia</title>
                    <meta name="description" content="One stop center site that provides up to date and latest information about Fixed Deposit Rates among all commercial banks in Malaysia." />
                </Helmet>
            </HelmetProvider>
            <Container className={classes.container}>
                <InfiniteScroll
                    dataLength={promoList.length}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    loader={
                        <Fragment>
                             <Grid container spacing={6} className={classes.skeletonXs}>
                                {
                                    dataSkeleton1.map((item, index) => (
                                        <Grid key={index} item xs={12} sm={6} md={4} lg={4}>
                                            <div className={classes.paper}>
                                                <Skeleton />
                                            </div>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                            <Grid container spacing={6} className={classes.skeletonSm}>
                                {
                                    dataSkeleton2.map((item, index) => (
                                        <Grid key={index} item xs={12} sm={6} md={4} lg={4}>
                                            <div className={classes.paper}>
                                                <Skeleton />
                                            </div>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                            <Grid container spacing={6} className={classes.skeletonLgMd}>
                                {
                                    dataSkeleton3.map((item, index) => (
                                        <Grid key={index} item xs={12} sm={6} md={4} lg={4}>
                                            <div className={classes.paper}>
                                                <Skeleton />
                                            </div>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </Fragment>
                    }
                    style={{overflow: "hidden"}}
                    endMessage={
                        promoList.length > 0 
                        ?   <p style={{ textAlign: "center" }}>
                                <b>No more data available!</b>
                            </p>
                        :   null
                    }
                >
                    <Grid container spacing={6}>
                        {   promoList.length > 0 
                            ?   [...promoList].map((item, index) => (
                                    <Grid key={index} item xs={12} sm={6} md={4} lg={4}>
                                        <div className={classes.paper}>
                                        {/* <LazyLoad
                                            key={index}
                                            height={100}
                                            offset={[-100, 100]}
                                            placeholder={<Spinner />}
                                        > */}
                                            <CardItem details={item} />
                                        {/* </LazyLoad> */}
                                        </div>
                                    </Grid>
                                ))
                            :   dataMessage 
                                ?   <div className={classes.noData}>
                                        <p style={{ textAlign: "center" }}>
                                            <b>{dataMessage}</b>
                                        </p>
                                    </div>
                                :   <div style={{ paddingTop: "10px" }}>
                                        &nbsp;
                                    </div>
                        }
                    </Grid>
                </InfiniteScroll>
            </Container>
        </Fragment>
    )
}

export default Dashboard;