import './App.css';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';
import Header from './components/Header';
import Footer from './components/Footer';
import { FilterProvider } from './components/FilterContext'
import ScrollArrow from './components/ScrollArrow';
import ReactGA from 'react-ga';

function initializeReactGA() {
  ReactGA.initialize('UA-172995558-1');
  ReactGA.pageview('/homepage');
}

function App() {
  initializeReactGA();
  return (
    <FilterProvider>
      <div className="pageContainer">
        <BrowserRouter>
          <ScrollArrow />
          <div className="contentWrap">
              <Header />
              <Routes />
          </div>
          <Footer />
        </BrowserRouter>
      </div>
    </FilterProvider>
  );
}

export default App;