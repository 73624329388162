import React, { Fragment } from "react";
import { Container } from "@material-ui/core";
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
        marginLeft: 0,
        paddingBottom: 20
    },
    link: {
        textAlign:"center",
        '& a': {
            textDecoration: 'none',
            fontWeight: 'bold',
            color: '#f50057'
        }
    },
  }),
);

const About = () =>{
    const classes = useStyles();

    return(
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <title>About Us - MYFDRATES.COM</title>
                    <meta name="description" content="Learn more about our site, disclaimer and consent." />
                </Helmet>
            </HelmetProvider>
            <Container component="main" classes={{root: classes.container}}>
                <div>
                    <h1>
                        About Us
                    </h1>
                    <p>
                        MYFDRATES.COM is one stop center site that provides up to date and latest information about Fixed Deposit Rates among all commercial banks in Malaysia.
                    </p>
                    <h1>Disclaimer</h1>
                    <p>
                    All the information on this website - myfdrates.com - is published in good faith and for general information purpose only. MYFDRATES.COM does not make any warranties about the completeness, reliability and accuracy of this information. Any action you take upon the information you find on this website (myfdrates.com), is strictly at your own risk. MYFDRATES.COM will not be liable for any losses and/or damages in connection with the use of our website. Our Disclaimer was generated with the help of the Disclaimer Generator and the Disclaimer Generator.
                    <br/><br/>
                    From our website, you can visit other websites by following hyperlinks to such external sites. While we strive to provide only quality links to useful and ethical websites, we have no control over the content and nature of these sites. These links to other websites do not imply a recommendation for all the content found on these sites. Site owners and content may change without notice and may occur before we have the opportunity to remove a link which may have gone 'bad'.
                    <br/><br/>
                    Please be also aware that when you leave our website, other sites may have different privacy policies and terms which are beyond our control. Please be sure to check the Privacy Policies of these sites as well as their "Terms of Service" before engaging in any business or uploading any information.
                    </p>
                    <h1>
                        Consent
                    </h1>
                    <p>
                    By using our website, you hereby consent to our disclaimer and agree to its terms.
                    </p>
                    <h1>
                        Update
                    </h1>
                    <p>
                        Should we update, amend or make any changes to this document, those changes will be prominently posted here.
                    </p>
                </div>
            </Container>
        </Fragment>
    )
}

export default About;